/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
    background-color: lightgray;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field {
    width: 100%;
    display: block;
}

.mat-raised-button {
    width: 100%;
    font-size: 20px;
}

.mat-raised-button, .mat-stroked-button, .mat-button-toggle-group {
    border-radius:36px!important;
  }
  
.mat-button-ripple {
    border-radius:36px!important;
}

.mat-meguro-pink {
    background-color: #EA3E6F;
    color: #fff;
}
